import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, Radio, RadioChangeEvent, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../../models/state'
import Column from 'antd/lib/table/Column'
import FooterDashboard from '../../../components/FooterDashboard'
import { ws } from '../../../functions/ws'
import { ActionCreators, ICustomer } from 'cestasorlando-common'

const Customers: React.FC = () => {

	const { userData, user } = useSelector((state: IState) => state)
	const [date, setDate] = useState('')
	const [radio, setRadio] = useState(2)
	const [radioTicket, setRadioTicket] = useState(1)
	const [nameFiltered, setNameFiltered] = useState('')
	const [emailFiltered, setEmailFiltered] = useState('')
	const [userDataFiltered, setUserDataFiltered] = useState(userData)

	const dispatch = useDispatch()

	useEffect(() => {
		setUserDataFiltered(userData)
		calculateFilter()
	}, [userData])

	useEffect(() => {
		calculateFilter()
	}, [nameFiltered, emailFiltered, radio, radioTicket])

	const [form] = Form.useForm()
	const [form2] = Form.useForm()

	const updateCustomers = () => {
		ws.send(ActionCreators.User.requestCustomers(date, user?.token ? user.token : ''))	
	}

	const saveDate = (value: any) => {
		const d = value?.year().toString() + '-' + (value?.month() +1).toString() + '-' + value?.date().toString()
		setDate(d)
	}

	const changeCheck = ({ target: { value } }: RadioChangeEvent) => {
		setRadio(value)
	}

	const changeCheckTicket = ({ target: { value } }: RadioChangeEvent) => {
		setRadioTicket(value)
	}

	const changeName = (event: any) => {
		setNameFiltered(event.target.value)
	}

	const changeEmail = (event: any) => {
		setEmailFiltered(event.target.value)
	}

	const calculateFilter = () => {
		if (userData) {
			let customers = userData.customers

			switch (radio) {
				case 1:
					customers = customers.filter(item => item.arrived)
					break
				case 2:
					customers = customers.filter(item => !item.arrived)
					break
				default:
					break
			}

			switch (radioTicket) {
				case 1:
					customers = customers.filter(item => item.hasTicket)
					break
				case 2:
					customers = customers.filter(item => !item.hasTicket)
					break
				default:
					break
			}

			if (nameFiltered) {
				const newNameFs = nameFiltered.split(' ')
				const newCustomers: ICustomer[] = []
				for (const customer of customers) {
					let check = true
					for (const newNameF of newNameFs) {
						if (!customer.name.toLowerCase().includes(newNameF.toLowerCase().trim()) && !customer.lastName.toLowerCase().includes(newNameF.toLowerCase().trim())) {
							check = false
							break
						}
					}
					if (check) {
						newCustomers.push(customer)
					}
				}
				customers = newCustomers
			}

			if (emailFiltered) {
				customers = customers.filter(item => item.email.toLowerCase().includes(emailFiltered.toLowerCase()))
			}

			setUserDataFiltered({...userData, customers})
		}
	}

	const resendEmail = (customerId: string) => {
		ws.send(ActionCreators.Customer.resendEmail(customerId, user?.token ? user.token : ''))
	}

	const updateCustomer = (type: number, customerId: string) => {
		const arrived = type === 1 ? true : false
		ws.send(ActionCreators.Customer.update({ customerId, arrived }, user?.token ? user.token : ''))
		if (userData && userData.customers) {
			const customers = userData.customers
			for (const customer of customers) {
				if (customer.customerId === customerId) {
					customer.arrived = arrived
				}
			}
			dispatch({ type: ActionCreators.UserData.responseCustomers, customers })
		}
	}

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Customers</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body'>
									<div className='row'>
										<Form
											form={form}
											className='ml-4'
											onFinish={updateCustomers}
											layout='inline'
											requiredMark={false}
										>
											<Form.Item>
												<DatePicker className='rounded' onChange={(value) => saveDate(value)} />
											</Form.Item>
											<Form.Item>
												<Button htmlType='submit' className='btn-primary ml-2 rounded'>Search</Button>
											</Form.Item>
										</Form>
									</div>
									<div className='row mt-3'>
										<Form
											form={form2}
											className='ml-4'
											layout='inline'
											requiredMark={false}
										>
											<Form.Item>
												<Input onChange={changeName} className='' placeholder='Name' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
											</Form.Item>
											<Form.Item>
												<Input onChange={changeEmail} className='' placeholder='Email' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
											</Form.Item>
											<Form.Item>
												<Radio.Group onChange={changeCheck} value={radio} className='ml-3'>
													<Radio value={1}>Checked</Radio>
													<Radio value={2}>Unchecked</Radio>
													<Radio value={3}>All</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item>
												<Radio.Group onChange={changeCheckTicket} value={radioTicket} className='ml-3'>
													<Radio value={1}>Ticket</Radio>
													<Radio value={2}>No Ticket</Radio>
													<Radio value={3}>All</Radio>
												</Radio.Group>
											</Form.Item>
										</Form>
									</div>
									<div className='row'>
										{userData?.customers?
											<div className='col-12'>
												<div className='card'>
													<div className='card-body'>
														<div className='timeline-count mt-2'>
															<div className='row'>
																<div className='table-responsive mt-0'>
																	<Table
																		dataSource={userDataFiltered?.customers}
																		rowClassName={(record) => record.arrived ? 'table-row-checked' :  ''}
																	>
																		<Column title='Name' dataIndex='name' key='name' />
																		<Column title='Last name' dataIndex='lastName' key='lastName' />
																		<Column title='Email' dataIndex='email' key='email' />
																		<Column title='Phone' dataIndex='phone' key='phone' />
																		<Column title='People in home' dataIndex='peopleInHome' key='peopleInHome' />
																		<Column title='Distribution date' dataIndex='distributionDate' key='distributionDate' />
																		<Column title='Schedule' dataIndex='schedule' key='schedule' />
																		<Column title='Has Ticket' dataIndex='hasTicket' key='hasTicket' render={(flag: boolean) => {
																			if (flag) {
																				return 'Yes'
																			} else {
																				return 'No'
																			}
																		}} />
																		<Column title='IP' dataIndex='ip' key='ip' />
																		<Column title='City IP' dataIndex='cityIp' key='cityIp' />
																		<Column title='Checked' dataIndex='arrived' key='arrived' render={(flag: boolean) => {
																			if (flag) {
																				return 'Yes'
																			} else {
																				return 'No'
																			}
																		}} />
																		<Column
																			title='Resend Email'
																			dataIndex='keys'
																			key='resendEmail'
																			render={(text, record: any) => (
																				<button type='button' onClick={() => resendEmail(record.customerId)} className='btn header-item noti-icon waves-effect'>
																					<i className='text-dark mdi mdi-send-circle m-0' style={{ fontSize: '30px' }}></i>
																				</button>
																			)}
																		/>
																		<Column
																			title='Check'
																			dataIndex='keys'
																			key='check'
																			render={(text, record: any) => (
																				<button type='button' onClick={() => updateCustomer(1, record.customerId)} className='btn header-item noti-icon waves-effect'>
																					<i className='text-dark mdi mdi-check-circle m-0' style={{ fontSize: '30px' }}></i>
																				</button>
																			)}
																		/>
																		<Column
																			title='Uncheck'
																			dataIndex='keys'
																			key='uncheck'
																			render={(text, record: any) => (
																				<button type='button' onClick={() => updateCustomer(2, record.customerId)} className='btn header-item noti-icon waves-effect'>
																					<i className='text-dark mdi mdi-alpha-x-circle m-0' style={{ fontSize: '30px' }}></i>
																				</button>
																			)}
																		/>
																	</Table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											: <div></div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default Customers