import { IUserData, IUserDataAction, UserDataActionTypes } from 'cestasorlando-common'

export const userData = (state: IUserData | null = null, action: IUserDataAction): IUserData | null => {
	switch (action.type) {
		case UserDataActionTypes.ResponseCustomers:
			return {
				...state,
				customers: action.customers
			}

		case UserDataActionTypes.ResponseCustomer:
			if (state?.customers) {
				return {
					...state,
					customer: action.customer
				}
			} else {
				return {
					customers: [],
					customer: action.customer
				}
			}

		case UserDataActionTypes.Unload:
			return null

		default:
			return state
	}
}
