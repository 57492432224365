import { EventEmitter } from 'events'
import { toast } from 'react-toastify'
import { store } from '../reducers'
import { history } from '../App'
import { ActionTypes, IAction, ToastActionTypes, timeSpan, log, warn, error, info } from 'cestasorlando-common'

export class ClientWS extends EventEmitter {
	public ws!: WebSocket

	constructor() {
		super()
		this.on(ActionTypes.WS.Info, this.onInfo.bind(this))
	}

	// private actionsQueue: IAction[] = []

	/** connect to websocket */
	public connect() {
		try {
			if (window.location.hostname === 'localhost') {
				this.ws = new WebSocket('ws://localhost:8000/ws')
			} else {
				this.ws = new WebSocket(`wss://${window.location.host}/ws`)
			}

			this.ws.onmessage = this.onMessage.bind(this)
			this.ws.onopen = this.onOpen.bind(this)
			this.ws.onclose = this.onClose.bind(this)
			this.ws.onerror = this.onError.bind(this)
		} catch (err) {
			this.reconnect()
		}
	}

	public send(action: IAction) {
		if (this.ws && this.ws.readyState === this.ws.OPEN) {
			log('sending:', action)
			this.ws.send(JSON.stringify(action))
		} else {
			// this.actionsQueue.push(action)
			warn('WS is not OPEN!')
		}
	}

	private onMessage(msg: any) {
		const data = JSON.parse(msg.data)
		log('receiving:', data)
		const actions: IAction[] = Array.isArray(data) ? data : [data]
		actions.forEach((action) => {
			this.emit(ActionTypes.WS.Action, action)
		})
	}

	private onOpen() {
		log('WS connected!')
		// while (this.actionsQueue.length > 0) {
		// 	const action = this.actionsQueue.shift()
		// 	if (action) {
		// 		this.send(action)
		// 	}
		// }
		// store.dispatch({ type: 'Connection.Change', connection: { status: true } } as IConnectionChange)
		this.emit(ActionTypes.WS.Open)
	}

	private onClose() {
		log('WS closed!')
		// store.dispatch({ type: 'Connection.Change', connection: { status: false } } as IConnectionChange)
		this.emit(ActionTypes.WS.Close)
		this.reconnect()
	}

	private reconnect() {
		setTimeout(this.connect.bind(this), timeSpan('5s'))
	}

	private onError(ev: Event) {
		error('WS error:', ev)
		this.emit(ActionTypes.WS.Error, ev)

		// console.error('ws error:', err)
		// this.emit(ActionTypes.WS.Error, err)
	}

	private onInfo(data: string) {
		info('WS info', data)
		this.emit(ActionTypes.WS.Info, data)
	}
}

export class WS extends ClientWS {
	constructor() {
		super()
		this.connect()
		this.on(ActionTypes.WS.Action, this.action)
	}

	// public init() {
	// 	this.connect()
	// 	this.route()
	// }

	// private route() {
	// 	this.on(ActionTypes.WS.Action, this.action)
	// }

	private action(action: IAction) {
		if (action.type === ToastActionTypes.Error) {
			toast.error(action.message)
			return
		}

		if (action.type === ToastActionTypes.Info) {
			toast.info(action.message, { autoClose: 2000 })
			return
		}

		if (action.type === ToastActionTypes.Success) {
			toast.success(action.message, { autoClose: 2000 })
			return
		}

		if (action.type === ToastActionTypes.Warn) {
			toast.warn(action.message)
			return
		}

		if (action.type === ToastActionTypes.Redirect) {
			history.push(action.path)
			return
		}

		store.dispatch(action)
	}
}

export const ws = new WS()
