import { Button, Checkbox, Form, Input, Modal, Select } from 'antd'
import axios from 'axios'
import { ActionCreators, ICustomer, sleep, ModalActionTypes } from 'cestasorlando-common'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Footer from '../../components/Footer'
import { ws } from '../../functions/ws'
import { IState } from '../../models/state'

const Home: React.FC = () => {

	const { page, parameters, modal } = useSelector((state: IState) => state)
	const [timeO, setTimeO] = useState(2000)
	const [aware, setAware] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if (modal && modal.backgroundColor && modal.fontColor) {
			checkModalColors(modal.backgroundColor, modal.fontColor)
		}
	}, [modal])

	const init = () => {
		if (!page) {
			ws.send(ActionCreators.Page.getContent(document.URL.replace('http://', '').replace('https://', '').replace('/', '')))
			//Sergio cancelando a distribuição
			// dispatch(ActionCreators.ModalMessage.multi({
			// 	message: ' DEVIDO A COMPLICAÇÕES TÉCNICAS, NÃO ESTAREMOS FAZENDO A DISTRIBUIÇÃO DESSA SEMANA. CONTAMOS COM SUA COMPREENSÃO!\n\n DUE TO TECHNICAL COMPLICATIONS, WE WILL NOT BE CONDUCTING DISTRIBUTION THIS WEEK. WE APPRECIATE YOUR UNDERSTANDING!\n\n - GALL ORLANDO!',
			// 	block: true,
			// 	qrCode: '',
			// 	title: 'SYSTEM CLOSED',
			// 	okButton: ' ',
			// 	backgroundColor: '#e51e26',
			// 	fontColor: '#FFFFFF',
			// 	buttonBackgroundColor: '#e51e26',
			// 	buttonFontColor: '#FFFFFF'
			// }))
		} else {
			document.title = page.title
			let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
			if (!link) {
				link = document.createElement('link')
				link.rel = 'icon'
				document.getElementsByTagName('head')[0].appendChild(link)
			}
			link.href = 'https://' + page.imgsUrl + '/favicon.ico'
			setTimeO(10000000000)
			if (modal?.message) {
				dispatch(ActionCreators.ModalMessage.show(true))
			}
		}
	}

	setTimeout(init, timeO)

	const handleSubmit = async (values: any) => {
		if (!aware || !page) {
			toast.error('Please, confirm you are aware of the rules', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}
		if (!parameters?.distributionDate) {
			toast.error('No distribution Date available. Please try again later', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}
		if (values.email !== values.confirmEmail) {
			toast.error('Email and Confirm Email not match. Please review', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}
		if (values.phone.replace(/ /g, '').length !== 10) {
			toast.error('Please input a valid Phone Number without country code (+1)', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}

		const sendButton = document.getElementById('buttonRegister') as HTMLButtonElement
		sendButton.disabled = true
		let resIp: any
		try {
			resIp = await axios.get('https://geolocation-db.com/json/')
		} catch (err) {
			resIp = null
		}
		const customer: ICustomer = {
			customerId: 'new',
			name: values.name,
			lastName: values.lastName,
			email: values.email,
			phone: values.phone,
			peopleInHome: values.peopleInHome,
			distributionDate: parameters!.distributionDate!,
			schedule: values.schedule,
			ip: resIp && resIp.data && resIp.data.IPv4 ? resIp.data.IPv4 : '',
			cityIp: resIp && resIp.data && resIp.data.city ? resIp.data.city : '',
			arrived: false,
			hasTicket: false,
		}
		// const resRegister = await axios.post(page.apiEndpoint + 'customer/register', {
		// 	url: document.URL.replace('http://', '').replace('https://', '').replace('/', ''),
		// 	customer,
		// })
		// if (resRegister.data.status === 0) {
		// 	console.log('entrou')
		// 	history.push('/success')
		// }
		ws.send(ActionCreators.Customer.register(customer, document.URL.replace('http://', '').replace('https://', '').replace('/', '')))
		form.resetFields()
		setAware(false)
		await sleep('10s')
		sendButton.disabled = false
	}

	const [form] = Form.useForm()

	const closeModal = async () => {
		dispatch({ type: ModalActionTypes.Multi, modalElements: { show: false, message: '', qrCode: '', title: '', okButton: '', backgroundColor: '', fontColor: '', buttonBackgroundColor: '', buttonFontColor: '' } })
	}

	const modalCancel = async () => {
		console.log('You must agree')
	}

	const checkModalColors = async (backgroundColor: string, color: string) => {
		const x = document.getElementsByClassName('ant-modal-header') as HTMLCollectionOf<HTMLElement>
		if (x.length > 0) {
			x[0].style.backgroundColor = backgroundColor
		}
		const x2 = document.getElementsByClassName('ant-modal-content') as HTMLCollectionOf<HTMLElement>
		if (x2.length > 0) {
			x2[0].style.backgroundColor = backgroundColor
		}
		const x3 = document.getElementsByClassName('ant-modal-title') as HTMLCollectionOf<HTMLElement>
		if (x3.length > 0) {
			x3[0].style.color = color
		}
	}

	const ModalMessage: React.FC = () => {
		return (
			<Modal
				title={modal?.title ? modal.title : page?.title}
				open={modal?.show ? true : false}
				centered
				width={800}
				okText={modal?.okButton ? modal.okButton : 'AGREE'}
				cancelText='CANCEL'
				onOk={() => closeModal()}
				onCancel={() => modalCancel()}
				bodyStyle={{ backgroundColor: modal?.backgroundColor ? modal?.backgroundColor : '#FFFFFF', color: modal?.fontColor ? modal?.fontColor : '#000000' }}
				footer={[
					<Button key='OK' onClick={closeModal} style={{ backgroundColor: modal?.buttonBackgroundColor ? modal?.buttonBackgroundColor : '#40a9ff', borderColor: modal?.buttonBackgroundColor ? modal?.buttonBackgroundColor : '#40a9ff', color: modal?.buttonFontColor ? modal?.buttonFontColor : '#000000' }}>
						{modal?.okButton ? modal.okButton : 'AGREE'}
					</Button>,
				]}
			>
				{modal?.message?.split('<br />').map((item) => {
					return (
						<>
							{ item }
							<br />
						</>
						)
				})}
				{modal?.qrCode ?
					<div className='card-body text-center'>
						{/* <img src={'https://chart.googleapis.com/chart?chs=200x200&chld=M%7C0&cht=qr&chl=' + encodeURIComponent(modal.qrCode)} alt='QR Code' className='mt-2' height='200' /> */}
						<img src={modal.qrCode} alt='QR Code' className='mt-2' height='200' /><br />
						{modal.qrCodeString}
					</div>
					: <div></div>
				}
			</Modal>
		)
	}

	return (
		<>
			<div id='preloader'>
				<div className='loader'>
					<svg className='circular' viewBox='25 25 50 50'>
						<circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth={3} strokeMiterlimit={10} />
					</svg>
				</div>
			</div>

			<div className='login-form-bg h-100 m-5'>
				<div className='container h-100'>
					<div className='row justify-content-center h-100'>
						<div className='col-xl-6'>
							<div className='error-content'>
								<div className='card mb-0'>
									<div className='card-body text-center'>
										<h1 className='error-text text-primary'>
											<img src={'https://' + page?.imgsUrl + '/logo.png'} width='200px' style={{ backgroundColor: page?.backgroundColorLogo }} />
										</h1>
										<div className='account-pages my-2 p-0'>
											<div className='card-body pt-0'>
												<div className='p-1'>
													<Form
														form={form}
														className='form-horizontal'
														onFinish={handleSubmit}
														layout='vertical'
														requiredMark={false}
														initialValues={{ name: '', lastName: '', email: '', confirmEmail: '', phone: '', peopleInHome: 1, schedule: '' }}
													>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Name' name='name' rules={[{ required: true, message: 'Input your name' },]}>
															<Input className='form-control' placeholder='Enter your name' style={{ borderWidth: '0 0 2px' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Last Name' name='lastName' rules={[{ required: true, message: 'Input your last name' },]}>
															<Input className='form-control' placeholder='Enter your last name' style={{ borderWidth: '0 0 2px' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Email' name='email' rules={[{ type: 'email' }, { required: true, message: 'Input your email'  },]}>
															<Input className='form-control' placeholder='Enter your email' style={{ borderWidth: '0 0 2px' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Confirm Email' name='confirmEmail' rules={[{ type: 'email' }, { required: true, message: 'Confirm your email'  },]}>
															<Input className='form-control' placeholder='Confirm your email' style={{ borderWidth: '0 0 2px' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Phone Number' name='phone' rules={[{ required: true, message: 'Input your phone' }, { max: 9999999999999, message: 'Input a correct number' },]}>
															<Input className='form-control' placeholder='Enter your phone number' style={{ borderWidth: '0 0 2px' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Number of People in Your Home' name='peopleInHome' rules={[{ required: true, message: 'Select the number of people in your home' },]}>
															<Select className='text-left'>
																<Select.Option key={1} value='1'>1</Select.Option>
																<Select.Option key={2} value='2'>2</Select.Option>
																<Select.Option key={3} value='3'>3</Select.Option>
																<Select.Option key={4} value='4'>4</Select.Option>
																<Select.Option key={5} value='5'>5</Select.Option>
																<Select.Option key={6} value='6'>6</Select.Option>
																<Select.Option key={7} value='7'>7</Select.Option>
																<Select.Option key={8} value='8'>8</Select.Option>
																<Select.Option key={9} value='9'>9</Select.Option>
																<Select.Option key={10} value='10'>10</Select.Option>
															</Select>
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Distribution Date' name='distributionDate' >
															<div className='text-left'>
																<label>{parameters?.distributionDate}</label>
															</div>
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Schedule' name='schedule' rules={[{ required: true, message: 'Select one schedule' },]}>
															<Select className='text-left'>
																{parameters?.schedules ?
																	parameters.schedules.map((item) => {
																		return (
																			<Select.Option key={item} value={item}>{item}</Select.Option>
																		)
																	})
																:
																	<Select.Option value=''> </Select.Option>
																}
															</Select>
														</Form.Item>
														<div className='text-left'>
															<Checkbox onChange={() => setAware(!aware)} checked={aware}>I am aware of the <a href={page?.rulesLink} target='_blank' rel='noreferrer'>Rules</a></Checkbox>
														</div>
														<Form.Item className='mt-4'>
															<Button className='btn-primary btn-block waves-effect waves-light' id='buttonRegister' htmlType='submit'>
																Submit
															</Button>
														</Form.Item>
													</Form>
												</div>
											</div>
										</div>
										<Footer />
									</div>
									<ModalMessage />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Home
