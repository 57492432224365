import React from 'react'
import { useSelector } from 'react-redux'
import { IState } from '../../../models/state'
import { useHistory } from 'react-router-dom'

const DHeader: React.FC = () => {

	const { user, page } = useSelector((state: IState) => state)
	const history = useHistory()

	const btnMenu = async () => {
		if (document.body.classList.contains('sidebar-enable')) {
			document.body.classList.remove('sidebar-enable')
		} else {
			document.body.classList.add('sidebar-enable')
		}
	}

	const btnMenuRemove = async () => {
		document.body.classList.remove('sidebar-enable')
	}

	return (
		<>
			<header id='page-topbar'>
				<div className='navbar-header'>
					<div className='container-fluid'>
						<div className='float-right'>
							<div className='dropdown d-inline-block'>
								<button type='button' onClick={() => btnMenuRemove()} className='btn header-item waves-effect' id='page-header-user-dropdown' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
									<img className='rounded-circle header-profile-user' alt='Header Avatar' src={'https://' + page?.imgsUrl + '/logo.png'} width='100px' style={{ backgroundColor: page?.backgroundColorLogo }} />
									<span className='d-none d-xl-inline-block ml-1'>{user?.name}</span>
									<i className='mdi mdi-chevron-down d-none d-xl-inline-block'></i>
								</button>
								<div className='dropdown-menu dropdown-menu-right'>
									<div className='dropdown-divider'></div>
									<a className='dropdown-item text-danger' onClick={() => history.push('/logout')}><i className='bx bx-power-off font-size-16 align-middle mr-1 text-danger'></i>Logout</a>
								</div>
							</div>
						</div>
						<div>
							<div className='navbar-brand-box'>
								<a onClick={() => {
									btnMenuRemove()
									history.push('/dashboard')
								}} className='logo logo-dark'>
									<span className='logo-sm'>
										<img src={'https://' + page?.imgsUrl + '/logo.png'} alt='' height='40' />
									</span>
									<span className='logo-lg'>
										<img src={'https://' + page?.imgsUrl + '/logo.png'} alt='' height='34' />
									</span>
								</a>
								<a onClick={() => {
									btnMenuRemove()
									history.push('/dashboard')
								}} className='logo logo-light'>
									{/* <span className='logo-sm'>
										<img src={'https://' + page?.imgsUrl + '/logo.png'} alt='' height='40' />
									</span>
									<span className='logo-lg'>
										<img src={'https://' + page?.imgsUrl + '/logo.png'} alt='' height='60' />
									</span> */}
								</a>
							</div>
							<button type='button' onClick={() => btnMenu()} className='btn btn-sm px-3 font-size-16 header-item toggle-btn waves-effect' id='vertical-menu-btn'>
								<i className='fa fa-fw fa-bars'></i>
							</button>
						</div>
					</div>
				</div>
			</header>
		</>
	)
}

export default DHeader
