import { IPage, IPageAction, PageActionTypes } from 'cestasorlando-common'

export const page = (state: IPage | null = null, action: IPageAction): IPage | null => {
	switch (action.type) {
		case PageActionTypes.LoadContent:
			return {
				...action.page,
			}
		
		case PageActionTypes.UnloadContent:
			return null
		
		default:
			return state
	}
}
