import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FooterDashboard from '../../../components/FooterDashboard'
import QrReader from 'react-qr-reader'
import { Button, Form, Input, Radio } from 'antd'
import { ws } from '../../../functions/ws'
import { ActionCreators } from 'cestasorlando-common'
import { IState } from '../../../models/state'
import { toast } from 'react-toastify'

const ValidateQrCode: React.FC = () => {

	const { userData, user } = useSelector((state: IState) => state)
	const dispatch = useDispatch()

	const [scanning, setScanning] = useState(false)
	const [code, setCode] = useState('')

	const [webcamResult, setWebcamResult] = useState()
	const webcamError = (error: any) => {
		if (error) {
			console.log(error)
		}
	}

	const [form] = Form.useForm()

	const webcamScan = (result: any) => {
		if (result) {
			ws.send(ActionCreators.Customer.validateQrCode(result.toString(), user?.token ? user.token : ''))
			setWebcamResult(result)
			setScanning(false)
		}
	}

	const openScan = () => {
		setWebcamResult(undefined)
		setScanning(true)
	}

	const updateCustomer = (type: number) => {
		const arrived = type === 1 ? true : false
		ws.send(ActionCreators.Customer.update({ customerId: userData?.customer?.customerId, arrived }, user?.token ? user.token : ''))
		const cust = {
			...userData?.customer,
			arrived,
		}
		dispatch({ type: ActionCreators.UserData.responseCustomer, customer: cust })
	}

	const checkByCode = () => {
		if (!code) {
			toast.error('Input a code', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}
		ws.send(ActionCreators.Customer.validateQrCode(code, user?.token ? user.token : ''))
		setWebcamResult(code as any)
		form.resetFields()
	}

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Validate QR Code</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body text-dark font-weight-medium font-size-16'>
										{webcamResult ?
											<div className='row'>
												<div className='col-xl-4'>
													<h6>Name</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.name}</p>
													<h6>Last Name</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.lastName}</p>
													<h6>Email</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.email}</p>
													<h6>Phone</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.phone}</p>
												</div>
												<div className='col-xl-4'>
													<h6>People in home</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.peopleInHome}</p>
													<h6>Distribution Date</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.distributionDate}</p>
													<h6>Schedule</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.schedule}</p>
													<h6>IP</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.ip}</p>
												</div>
												<div className='col-xl-4'>
													<h6>City IP</h6>
													<p className='text-muted font-weight-medium mt-1 mb-5'>{userData?.customer?.cityIp}</p>
													<h6>Checked</h6>
													{userData?.customer?.arrived ?
														<p className='text-muted font-weight-medium mt-1 mb-5'>Yes</p>
														: <p className='text-muted font-weight-medium mt-1 mb-5'>No</p>
													}
												<p><button className='rounded font-size-14 btn btn-success' style={{ width: '10rem' }} onClick={() => updateCustomer(1)}>Check</button></p>
												<p><button className='rounded font-size-14 btn btn-danger' style={{ width: '10rem' }} onClick={() => updateCustomer(2)}>Uncheck</button></p>
												</div>
											</div>
											: <div></div>
										}
										<>
											{scanning ?
												<div className='card col-sm-4'>
													<div className='card-body text-center'>
														<QrReader
															delay={300}
															onError={webcamError}
															onScan={webcamScan}
															legacyMode={false}
															facingMode={"environment"}
														/>
													</div>
													<div className='rounded mb-1'>
														Code readed: {webcamResult}
													</div>
													<Button className='rounded mt-3 font-size-14' type='primary' onClick={() => setScanning(false)}>Back</Button>
												</div>
											:
											<>
												<div className='row'>
													<div className='card col-sm-4 mt-3'>
														<Button className='rounded font-size-14' type='primary' onClick={openScan}>New Scan</Button>
													</div>
												</div>
												<div className='row'>
													<div className='card col-xl-6 mt-3'>
														<Form
															form={form}
															className=''
															layout='inline'
															requiredMark={false}
															onFinish={checkByCode}
														>
															<Form.Item>
																<Input onChange={event => setCode(event.target.value.toString())} className='' placeholder='Code' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
															</Form.Item>
															<Button type='primary' className='rounded font-size-14' htmlType='submit'>
																Check By Code
															</Button>
														</Form>
													</div>
												</div>
											</>
											}
										</>
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default ValidateQrCode
