import { IModalElements, IModalAction, ModalActionTypes } from 'cestasorlando-common'

export const modal = (state: IModalElements | null = null, action: IModalAction): IModalElements | null => {
	switch (action.type) {
		case ModalActionTypes.Show:
			return {
				...state,
				show: action.show,
			}

		case ModalActionTypes.Message:
			return {
				...state,
				message: action.message,
			}

		case ModalActionTypes.QrCode:
			return {
				...state,
				qrCode: action.qrCode,
			}

		case ModalActionTypes.Multi:
			if (state && state.block) {
				return state
			}
			return {
				...state,
				show: action.modalElements.show,
				message: action.modalElements.message,
				qrCode: action.modalElements.qrCode,
				title: action.modalElements.title,
				okButton: action.modalElements.okButton,
				backgroundColor: action.modalElements.backgroundColor,
				fontColor: action.modalElements.fontColor,
				buttonBackgroundColor: action.modalElements.buttonBackgroundColor,
				buttonFontColor: action.modalElements.buttonFontColor,
				block: action.modalElements.block,
				qrCodeString: action.modalElements.qrCodeString,
			}

		case ModalActionTypes.Unload:
			return null

		default:
			return state
	}
}
