import React from 'react'
import { Button, Form } from 'antd'
import { useSelector } from 'react-redux'
import { IState } from '../../../models/state'
import FooterDashboard from '../../../components/FooterDashboard'
import { ws } from '../../../functions/ws'
import { ActionCreators } from 'cestasorlando-common'

const CancelDistribution: React.FC = () => {

	const { parameters } = useSelector((state: IState) => state)

	const [form] = Form.useForm()

	const CancelDistribution = () => {
		ws.send(ActionCreators.User.cancelCurrentDistribution())
	}

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Cancel Distribution</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body'>
									<div className='row'>
											<div className='col-12'>
												<div className='card'>
													<div className='card-body'>
														<div className='row'>
															<div className='col-xl-12'>
																<h6>Current Distribution Date: <span className='text-muted font-weight-medium'>{parameters?.distributionDate}</span></h6>
															</div>
														</div>
													</div>
												</div>
											</div>
									</div>
									<div className='row'>
										<Form
											form={form}
											className='ml-4'
											onFinish={CancelDistribution}
											layout='inline'
											requiredMark={false}
										>
											<Form.Item>
												<Button htmlType='submit' className='btn-primary ml-2 rounded'>Cancel Current Distribution</Button>
											</Form.Item>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default CancelDistribution