import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import Header from './DHeader'
import Sidebar from './DSidebar'
import { IState } from '../../models/state'
import DashboarAdmindHome from './DashboardHome'
import Customers from './Customers'
import ValidateQrCode from './ValidateQrCode'
import CreateUser from './CreateUser'
import SummaryReport from './SummaryReport'
import CancelDistribution from './CancelDistribution'

const Dashboard: React.FC = () => {
	const user = useSelector((state: IState) => state.user)

	if (!user) {
		return <Redirect to='/' />
	}

	return (
		<>
			<div className='container-fluid'>
				<div id='layout-wrapper'>
					<Sidebar />
					<Header />
					<Switch>
						<Route path='/dashboard' exact component={DashboarAdmindHome} />
						<Route path='/dashboard/customers' exact component={Customers} />
						<Route path='/dashboard/validateqrcode' exact component={ValidateQrCode} />
						<Route path='/dashboard/createuser' exact component={CreateUser} />
						<Route path='/dashboard/summaryreport' exact component={SummaryReport} />
						<Route path='/dashboard/canceldistribution' exact component={CancelDistribution} />
					</Switch>
				</div>
			</div>
		</>
	)
}

export default Dashboard
