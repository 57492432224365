import { Button, Form, Input } from 'antd'
import { ActionCreators, sleep } from 'cestasorlando-common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../../components/Footer'
import { ws } from '../../functions/ws'
import { IState } from '../../models/state'

const Login: React.FC = () => {

	const { page, user } = useSelector((state: IState) => state)
	const [timeO, setTimeO] = useState(2000)

	const init = () => {
		if (!page) {
			let url = document.URL.replace('http://', '').replace('https://', '')
			if (url.search('/') > 0) {
				url = url.substring(0, url.search('/'))
			}
			ws.send(ActionCreators.Page.getContent(url))
		} else {
			document.title = page.title

			let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
			if (!link) {
				link = document.createElement('link')
				link.rel = 'icon'
				document.getElementsByTagName('head')[0].appendChild(link)
			}
			link.href = 'https://' + page.imgsUrl + '/favicon.ico'
			setTimeO(10000000000)
		}
	}

	setTimeout(init, timeO)

	const onSubmit = async (v: any) => {
		const { email, password, token } = v
		const sendButton = document.getElementById('buttonLogin') as HTMLButtonElement
		sendButton.disabled = true

		try {
			let url = document.URL.replace('http://', '').replace('https://', '')
			if (url.search('/') > 0) {
				url = url.substring(0, url.search('/'))
			}
			ws.send(ActionCreators.User.login(email, password, url))
		} catch (e) {
			toast.error('Try again later!', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
		}
		await sleep('10s')
		sendButton.disabled = false
	}

	const [form] = Form.useForm()

	if (user !== null) {
		return <Redirect to='/dashboard'></Redirect>
	}

	return (
		<>
			<div id='preloader'>
				<div className='loader'>
					<svg className='circular' viewBox='25 25 50 50'>
						<circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth={3} strokeMiterlimit={10} />
					</svg>
				</div>
			</div>

			<div className='login-form-bg h-100 m-5'>
				<div className='container h-100'>
					<div className='row justify-content-center h-100'>
						<div className='col-xl-6'>
							<div className='error-content'>
								<div className='card mb-0'>
									<div className='card-body text-center'>
										<h1 className='error-text text-primary'>
											<img src={'https://' + page?.imgsUrl + '/logo.png'} width='200px' style={{ backgroundColor: page?.backgroundColorLogo }} />
										</h1>
										<div className='account-pages my-2 p-0'>
											<div className='card-body pt-0'>
												<div className='p-1'>
													<Form
														form={form}
														className='form-horizontal'
														onFinish={onSubmit}
														layout='vertical'
														requiredMark={false}
														initialValues={{ email: '', password: '' }}
													>
														<Form.Item className='form-group' style={{ margin: '0', marginBottom: '0.7rem' }} label='Email' name='email' rules={[{ required: true, message: 'Input your email' },]}>
															<Input className='' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
														</Form.Item>
														<Form.Item style={{ margin: '0', marginBottom: '0.7rem' }} label='Password' name='password' rules={[{ required: true, message: 'Input your password' },]}>
															<Input.Password id='passInput' className='' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
														</Form.Item>
														<Form.Item className='mt-4'>
															<Button type='primary' className='btn-primary btn-block waves-effect waves-light' id='buttonLogin' htmlType='submit'>
																Login
															</Button>
														</Form.Item>
														<div className='mt-4 text-center'>
															<a href='/forgotpassword' className='text-muted'><i className='mdi mdi-lock mr-1'></i>Reset Password</a>
														</div>
													</Form>
												</div>
											</div>
										</div>
										<Footer />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login