import React from 'react'
import { Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import Routes from './routes'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/base/global'
import { Default } from './styles/theme'

export const history = createBrowserHistory()

export const App: React.FC = () => {

	return (
		<>
			<Router history={history}>
				<ThemeProvider theme={Default}>
					<Routes />
					<ToastContainer />
				</ThemeProvider>
				<GlobalStyle />
			</Router>
		</>
	)
}
