import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

	body {
		font-family: "SF UI Text",sans-serif;
		font-size: .875rem;
		font-weight: 400;
		line-height: 1.5;
		color: #8687a7;
		text-align: left;
		background-color: #f3f5f7;
	}

`
