import React from 'react'

const FooterDashboard: React.FC = () => {

	return (
		<>
			<footer className='footer'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm-6'>
							© {(new Date().getFullYear())}
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default FooterDashboard
