import React from 'react'
import { useSelector } from 'react-redux'
import { IState } from '../../../models/state'
import { useHistory } from 'react-router-dom'
import { ActionCreators } from 'cestasorlando-common'
import { ws } from '../../../functions/ws'

const Sidebar: React.FC = () => {

const { user } = useSelector((state: IState) => state)

	const history = useHistory()

	const btnMenuRemove = async () => {
		document.body.classList.remove('sidebar-enable')
	}

	return (
		<>
			<div className='vertical-menu'>
				<div className='h-100'>
					<div className='user-wid text-center py-4'>
						<div className='mt-3'>
							<a onClick={() => {
								btnMenuRemove()
								history.push('/dashboard')
							}} className='text-dark font-weight-medium font-size-16'>{user?.name}</a>
							<p className='text-body mt-1 mb-0 font-size-13'>{user?.email}</p>
						</div>
					</div>
					<div id='sidebar-menu'>
						<ul className='metismenu list-unstyled' id='side-menu'>
							<li className='menu-title'>Menu</li>
							<li>
								<a onClick={() => {
									btnMenuRemove()
									history.push('/dashboard')
								}} className='waves-effect'>
									<i className='mdi mdi-monitor-dashboard'></i>
									<span>Dashboard</span>
								</a>
							</li>
							<li>
								<a onClick={() => {
									ws.send(ActionCreators.User.requestCustomers(undefined, user?.token ? user.token : ''))
									btnMenuRemove()
									history.push('/dashboard/customers')
								}} className=' waves-effect'>
									<i className='mdi mdi-account-multiple'></i>
									<span>List Customers</span>
								</a>
							</li>
							<li>
								<a onClick={() => {
									btnMenuRemove()
									history.push('/dashboard/validateqrcode')
								}} className='waves-effect'>
									<i className='mdi mdi-qrcode'></i>
									<span>Validate QR Code</span>
								</a>
							</li>
							<li>
								<a onClick={() => {
									btnMenuRemove()
								}} className='has-arrow waves-effect'>
									<i className='mdi mdi-file-chart'></i>
									<span>Reports</span>
								</a>
								<ul className='sub-menu' aria-expanded='false'>
									<li><a onClick={() => {
										ws.send(ActionCreators.Report.summaryRequest(undefined, user?.token ? user.token : ''))
										btnMenuRemove()
										history.push('/dashboard/summaryreport')
									}} className='waves-effect'>
										<i className='mdi mdi-alpha-s-circle'></i>
										<span>Summary</span>
									</a></li>
								</ul>
							</li>
							{user?.role === 'admin' ?
								<>
									<li>
										<a onClick={() => {
											btnMenuRemove()
											history.push('/dashboard/canceldistribution')
										}} className=' waves-effect'>
											<i className='mdi mdi-cancel'></i>
											<span>Cancel Distribution</span>
										</a>
									</li>
									<li>
										<a onClick={() => {
											btnMenuRemove()
											history.push('/dashboard/createuser')
										}} className='waves-effect'>
											<i className='mdi mdi-account-plus'></i>
											<span>Create User</span>
										</a>
									</li>
								</>
								: <div></div>
							}
							<li>
								<a onClick={() => history.push('/logout')} className=' waves-effect'>
									<i className='bx bx-power-off'></i>
									<span>Logout</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sidebar
