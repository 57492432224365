import { ActionCreators } from 'cestasorlando-common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../../components/Footer'
import { ws } from '../../functions/ws'
import { IState } from '../../models/state'

const InvalidEmail: React.FC = () => {

	const { page } = useSelector((state: IState) => state)
	const [timeO, setTimeO] = useState(2000)

	const init = () => {
		if (!page) {
			let url = document.URL.replace('http://', '').replace('https://', '')
			if (url.search('/') > 0) {
				url = url.substring(0, url.search('/'))
			}
			ws.send(ActionCreators.Page.getContent(url))
		} else {
			document.title = page.title

			let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
			if (!link) {
				link = document.createElement('link')
				link.rel = 'icon'
				document.getElementsByTagName('head')[0].appendChild(link)
			}
			link.href = 'https://' + page.imgsUrl + '/favicon.ico'
			setTimeO(10000000000)
		}
	}

	setTimeout(init, timeO)

	return (
		<>
			<div id='preloader'>
				<div className='loader'>
					<svg className='circular' viewBox='25 25 50 50'>
						<circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth={3} strokeMiterlimit={10} />
					</svg>
				</div>
			</div>

			<div className='login-form-bg h-100 m-5'>
				<div className='container h-100'>
					<div className='row justify-content-center h-100'>
						<div className='col-xl-6'>
							<div className='error-content'>
								<div className='card mb-0'>
									<div className='card-body text-center'>
										<h1 className='error-text text-primary'>
											<img src={'https://' + page?.imgsUrl + '/logo.png'} width='200px' style={{ backgroundColor: page?.backgroundColorLogo }} />
										</h1>
										<div className='account-pages my-2 p-0'>
											<div className='card-body pt-0'>
												<div className='p-1'>
													<div className='mt-4 text-center'>
														<a href='/login' className='text-muted'>Invalid Link</a>
													</div>
													<div className='mt-4 text-center'>
														<a href='/login' className='text-muted'><i className='mdi mdi-lock mr-1'></i>Login</a>
													</div>
												</div>
											</div>
										</div>
										<Footer />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default InvalidEmail