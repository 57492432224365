import { Button, Form, Input } from 'antd'
import { ActionCreators, ActionTypes, sleep } from 'cestasorlando-common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import Footer from '../../components/Footer'
import { ws } from '../../functions/ws'
import { IState } from '../../models/state'
import PasswordStrengthBar from 'react-password-strength-bar'

interface IMatchProps {
	email: string
	hash: string
}

const CreatePassword: React.FC = (props: any) => {
	const { email, hash } = props.match.params as IMatchProps
	ws.on(ActionTypes.WS.Open, () => {
		ws.send(ActionCreators.User.checkHashResetPassword(email, hash))
	})
	history.pushState(null, '', 'reset')

	const { page, user } = useSelector((state: IState) => state)
	const [timeO, setTimeO] = useState(2000)
	const [pass, setPassword] = useState('')
	const [score, setScore] = useState(0)

	const init = () => {
		if (!page) {
			let url = document.URL.replace('http://', '').replace('https://', '')
			if (url.search('/') > 0) {
				url = url.substring(0, url.search('/'))
			}
			ws.send(ActionCreators.Page.getContent(url))
		} else {
			document.title = page.title

			let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
			if (!link) {
				link = document.createElement('link')
				link.rel = 'icon'
				document.getElementsByTagName('head')[0].appendChild(link)
			}
			link.href = 'https://' + page.imgsUrl + '/favicon.ico'
			setTimeO(10000000000)
		}
	}

	setTimeout(init, timeO)

	const onSubmit = async (v: any) => {
		const { password, confirmPassword } = v
		if (!password || !confirmPassword || password !== confirmPassword) {
			toast.error('Password does not match')
			return
		}

		if (score < 2) {
			toast.error('Choose a stronger password')
			return
		}

		const sendButton = document.getElementById('sendButton') as HTMLButtonElement
		sendButton.disabled = true

		ws.send(ActionCreators.User.changePassword(password))
	}

	const [form] = Form.useForm()

	if (user !== null) {
		return <Redirect to='/dashboard'></Redirect>
	}

	return (
		<>
			<div id='preloader'>
				<div className='loader'>
					<svg className='circular' viewBox='25 25 50 50'>
						<circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth={3} strokeMiterlimit={10} />
					</svg>
				</div>
			</div>

			<div className='login-form-bg h-100 m-5'>
				<div className='container h-100'>
					<div className='row justify-content-center h-100'>
						<div className='col-xl-6'>
							<div className='error-content'>
								<div className='card mb-0'>
									<div className='card-body text-center'>
										<h1 className='error-text text-primary'>
											<img src={'https://' + page?.imgsUrl + '/logo.png'} width='200px' style={{ backgroundColor: page?.backgroundColorLogo }} />
										</h1>
										<div className='account-pages my-2 p-0'>
											<div className='card-body pt-0'>
												<div className='p-1'>
													<Form
														form={form}
														className='form-horizontal'
														onFinish={onSubmit}
														layout='vertical'
														requiredMark={false}
														initialValues={{ password: '', confirmPassword: '' }}
													>
														<Form.Item className='form-group' style={{ margin: '0', marginBottom: '0.7rem' }} label='New Password' name='password' rules={[{ required: true, message: 'Input your password' },]}>
															<Input.Password className='' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} onChange={(e) => setPassword(e.target.value)} />
														</Form.Item>
														<PasswordStrengthBar minLength={8} password={pass} onChangeScore={(newScore) => setScore(newScore)} />
														<Form.Item style={{ margin: '0', marginBottom: '0.7rem' }} label='Confirm Password' name='confirmPassword' rules={[{ required: true, message: 'Confirm your password' },]}>
															<Input.Password id='passInput' className='' style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} />
														</Form.Item>
														<Form.Item className='mt-4'>
															<Button type='primary' className='btn btn-primary btn-block waves-effect waves-light' id='sendButton' htmlType='submit'>
																Reset Password
															</Button>
														</Form.Item>
													</Form>
												</div>
											</div>
										</div>
										<Footer />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreatePassword