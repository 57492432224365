import { IParameters, IParametersAction, ParametersActionTypes } from 'cestasorlando-common'

export const parameters = (state: IParameters | null = null, action: IParametersAction): IParameters | null => {
	switch (action.type) {
		case ParametersActionTypes.LoadParameters:
			return {
				...action.parameters,
			}
		
		case ParametersActionTypes.UnloadParameters:
			return null
		
		default:
			return state
	}
}
