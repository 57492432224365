import React, { useState } from 'react'
import { Button, DatePicker, Form, Table } from 'antd'
import { useSelector } from 'react-redux'
import { IState } from '../../../models/state'
import Column from 'antd/lib/table/Column'
import FooterDashboard from '../../../components/FooterDashboard'
import { ws } from '../../../functions/ws'
import { ActionCreators } from 'cestasorlando-common'

const SummaryReport: React.FC = () => {

	const { reports, user } = useSelector((state: IState) => state)
	const [date, setDate] = useState('')

	const [form] = Form.useForm()

	const updateReport = () => {
		ws.send(ActionCreators.Report.summaryRequest(date, user?.token ? user.token : ''))
	}

	const saveDate = (value: any) => {
		const d = value?.year().toString() + '-' + (value?.month() +1).toString() + '-' + value?.date().toString()
		setDate(d)
	}

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Summary Report</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body'>
									<div className='row'>
										<Form
											form={form}
											className='ml-4'
											onFinish={updateReport}
											layout='inline'
											requiredMark={false}
										>
											<Form.Item>
												<DatePicker className='rounded' onChange={(value) => saveDate(value)} />
											</Form.Item>
											<Form.Item>
												<Button htmlType='submit' className='btn-primary ml-2 rounded'>Search</Button>
											</Form.Item>
										</Form>
									</div>
									<div className='row'>
										{reports?.summary?
											<div className='col-12'>
												<div className='card'>
													<div className='card-body'>
														<div className='row'>
															<div className='col-xl-4'>
																<h6>Tickets Delivered: <span className='text-muted font-weight-medium'>{reports.summary.ticketsDelivered}</span></h6>
																<h6 className='ml-4 text-success'>Tickets Checked: <span className='text-success font-weight-medium'>{reports.summary.deliveredChecked}</span></h6>
																<h6 className='ml-5 text-success'>People in Home Tickets Checked: <span className='text-success font-weight-medium'>{reports.summary.sumOfPeopleChecked}</span></h6>
																<h6 className='ml-4 text-danger'>Tickets Not Checked: <span className='text-danger font-weight-medium'>{reports.summary.deliveredNotChecked}</span></h6>
																<h6 className='ml-5 text-danger'>People in Home Tickets Not Checked: <span className='text-danger font-weight-medium'>{reports.summary.sumOfPeopleNotChecked}</span></h6>
															</div>
															<div className='col-xl-4'>
																<h6>Tickets not Delivered: <span className='text-muted font-weight-medium'>{reports.summary.ticketsNotDelivered}</span></h6>
																<h6 className='ml-4'>Unsubscribes: <span className='text-muted font-weight-medium'>{reports.summary.unsubscribes}</span></h6>
															</div>
															<div className='col-xl-4'>
																<h6>Total: <span className='text-muted font-weight-medium'>{reports.summary.ticketsDelivered + reports.summary.ticketsNotDelivered}</span></h6>
															</div>
														</div>
													</div>
												</div>
											</div>
											: <div></div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default SummaryReport