import React from 'react'
import { Button, Form, Input } from 'antd'
import FooterDashboard from '../../../components/FooterDashboard'
import { ws } from '../../../functions/ws'
import { ActionCreators } from 'cestasorlando-common'
import { toast } from 'react-toastify'

const CreateUser: React.FC = () => {

	const [form] = Form.useForm()

	const onSubmit = (values: any) => {
		if (!values.name || !values.email) {
			toast.error('Input a name and an email', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
			return
		}

		ws.send(ActionCreators.User.createUser(values.name, values.email))
	}

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Create User</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body m-3'>
									<Form
										form={form}
										layout='vertical'
										requiredMark={false}
										onFinish={onSubmit}
										initialValues={{ name: '', email: '' }}
									>
										<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Name' name='name' rules={[{ required: true, message: 'Input a name' },]}>
											<Input className='form-control' placeholder='Enter a name' style={{ borderWidth: '0 0 2px' }} />
										</Form.Item>
										<Form.Item style={{ margin: '0', marginBottom: '1rem' }} label='Email' name='email' rules={[{ type: 'email' }, { required: true, message: 'Input an email'  },]}>
											<Input className='form-control' placeholder='Enter an email' style={{ borderWidth: '0 0 2px' }} />
										</Form.Item>
										<Form.Item className='mt-4'>
											<Button type='primary' className='btn-primary btn-block waves-effect waves-light' id='buttonSend' htmlType='submit'>
												Create User
											</Button>
										</Form.Item>
									</Form>
									<div className='row mt-3 ml-2'>
										<div className='text-left text-size-16'>
											After Create, user will receive an email to create a password.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default CreateUser