import { IReportAction, IReports, ReportActionTypes } from 'cestasorlando-common'

export const reports = (state: IReports | null = null, action: IReportAction): IReports | null => {
	switch (action.type) {
		case ReportActionTypes.SummaryResponse:
			return {
				...state,
				summary: action.summary,
			}
		
		case ReportActionTypes.Unload:
			return null

		default:
			return state
	}
}
