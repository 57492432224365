import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import expireIn from 'redux-persist-transform-expire-in'
import storage from 'redux-persist/lib/storage'
import { IState } from '../models/state'
import { timeSpan } from 'cestasorlando-common'
import { page } from './page'
import { parameters } from './parameters'
import { modal } from './modal'
import { user } from './user'
import { userData } from './userData'
import { reports } from './reports'

const expireTime = timeSpan('180m') // expire in 2h
const expirationKey = 'expirationKeyCestasOrlando'

const reducers = combineReducers<IState>({
	page,
	parameters,
	modal,
	user,
	userData,
	reports,
})

const persistConfig = {
	key: 'cestasorlando',
	storage,
	whitelist: [],
	transforms: [expireIn(expireTime, expirationKey, [])],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const initialState: IState = {
	page: null,
	parameters: null,
	modal: null,
	user: null,
	userData: null,
	reports: null,
}

export const store = createStore(
	persistedReducer,
	initialState,
	(window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
)

export const persistor = persistStore(store)
