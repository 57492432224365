import React from 'react'
import { Facebook, Instagram } from 'react-feather'
import { useSelector } from 'react-redux'
import { IState } from '../../models/state'

const Footer: React.FC = () => {

	const { page } = useSelector((state: IState) => state)

	return (
		<>
			<div className='text-center pb-3'>
				<p>Copyright © {new Date().getFullYear()} - {page?.title} - All Rights Reserved</p>
			</div>
		</>
	)
}

export default Footer
