import { ActionCreators } from 'cestasorlando-common'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { history } from '../../App'
import { ws } from '../../functions/ws'

export const Logout: React.FC = () => {
	ws.send(ActionCreators.User.logout())
	const dispatch = useDispatch()
	dispatch(ActionCreators.User.unload())
	dispatch(ActionCreators.Parameters.unloadParameters())
	dispatch(ActionCreators.Page.unloadContent())
	dispatch(ActionCreators.Report.unload())
	dispatch(ActionCreators.ModalMessage.unload())
	dispatch(ActionCreators.UserData.unload())
	history.push('/login')
	return <Redirect to='/login' />
}
