import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../pages/Home'
import { PrivateRoute } from '../components/PrivateRoute'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import { Logout } from '../pages/Logout'
import ForgotPassword from '../pages/ForgotPassword'
import CreatePassword from '../pages/CreatePassword'
import InvalidEmail from '../pages/InvalidEmail'
import Unsubscribe from '../pages/Unsubscribe'

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route path='/' exact component={Home} />
			<Route path='/login' exact component={Login} />
			<Route path='/logout' exact component={Logout} />
			<Route path='/forgotpassword' exact component={ForgotPassword} />
			<Route path='/createpassword/:email?/:hash?' component={CreatePassword} />
			<Route path='/invalidemail' exact component={InvalidEmail} />
			<Route path='/unsubscribe/:hash?' component={Unsubscribe} />
			<PrivateRoute path='/dashboard' component={Dashboard} />
		</Switch>
	)
}

export default Routes
