import { IUser, IUserAction, UserActionTypes } from 'cestasorlando-common'

export const user = (state: IUser | null = null, action: IUserAction): IUser | null => {
	switch (action.type) {
		case UserActionTypes.Load:
			return {
				...action.user,
			}

		case UserActionTypes.Unload:
			return null

		case UserActionTypes.Update:
			if (state === null) {
				return null
			}

			return {
				...state,
				...action.user,
			}

		default:
			return state
	}
}
