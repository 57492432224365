import React from 'react'
import FooterDashboard from '../../../components/FooterDashboard'

const DashboardAdminHome: React.FC = () => {

	return (
		<>
			<div className='main-content'>
				<div className='page-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='page-title-box d-flex align-items-center justify-content-between'>
								<h4 className='page-title mb-0 font-size-18'>Dashboard</h4>
								<div className='page-title-right'>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body'>
									
								</div>
							</div>
						</div>
					</div>
					<div className='d-none d-block d-lg-none mt-5'>
						<div className='row'>
							<div className='col'>
								<FooterDashboard />
							</div>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-block'>
					<FooterDashboard />
				</div>
			</div>
		</>
	)
}

export default DashboardAdminHome
